import React, { useState } from 'react';
import './App.css';
import Page0 from './components/Page0';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';
import Page4 from './components/Page4';

function App() {
  const [currentPage, setCurrentPage] = useState('Page0');
  const [formData, setFormData] = useState({});

  const renderPage = () => {
    switch (currentPage) {
      case 'Page0':
        return <Page0 setCurrentPage={setCurrentPage} />;
      case 'Page1':
        return <Page1 setCurrentPage={setCurrentPage} formData={formData} setFormData={setFormData} />;
      case 'Page2':
        return <Page2 setCurrentPage={setCurrentPage} formData={formData} setFormData={setFormData} />;
      case 'Page3':
        return <Page3 setCurrentPage={setCurrentPage} formData={formData} setFormData={setFormData} />;
      case 'Page4':
        return <Page4 setCurrentPage={setCurrentPage} formData={formData} setFormData={setFormData} />;
      default:
        return <Page0 setCurrentPage={setCurrentPage} formData={formData} setFormData={setFormData} />;
    }
  };

  return (
    <div>
      {renderPage()}
    </div>
  );
}

export default App;
