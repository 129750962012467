import React, { useState, useRef, useEffect } from 'react';
import styles from './Page1.module.css';

const Page1 = ({setCurrentPage, formData, setFormData}) => {
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [features, setFeatures] = useState([
    "Perfect match (100/100) with must-have requirements",
    "Perfect match (100/100) with nice-to-have requirements",
    "Education in relevant field/discipline",
    "Formal education level",
    "Relevant professional experiences",
    "Having professional experiences in a different field of interest",
    "Quantity of previous experiences (the more the better)",
    "Duration of previous experiences (the longer the better)",
    "Relevance ONLY of the latest work experience",
    "Prestige of past experiences",
    "Meeting ONLY the hard skills",
    "Language Level requested in the job offer",
    "Having the required licenses",
    "Candidate matching Diversity & Inclusion policies’ criteria",
    "Cultural and Value fit for the company",
    "Availability to flexible working hours",
    "Distance from candidate and job’s location",
    "Not being overqualified",
    "Law and policy compliance (visa requirements etc)",
    "Communication and social skills",
    "Different interesting hobbies",
    "Motivation and Resourcefulness",
    "Lack of experience compensated by a strong educational background",
  ]);
  const [isAddingFeature, setIsAddingFeature] = useState(false);
  const [newFeature, setNewFeature] = useState("");
  const inputRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleCheckboxChange = (feature) => {
    setSelectedFeatures((prevSelectedFeatures) => {
      const featureIndex = prevSelectedFeatures.findIndex(item => item.feature === feature);
      if (featureIndex >= 0) {
        // Remove the feature if it exists
        const newSelectedFeatures = [...prevSelectedFeatures];
        newSelectedFeatures.splice(featureIndex, 1);
        return newSelectedFeatures;
      } else {
        // Add the feature with default priority if it doesn't exist
        return [...prevSelectedFeatures, { feature, priority: 'Must have' }];
      }
    });
  };
  

  const handlePriorityChange = (feature, priority) => {
    setSelectedFeatures((prevSelectedFeatures) =>
      prevSelectedFeatures.map((item) =>
        item.feature === feature ? { ...item, priority } : item
      )
    );
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedFeatures.length === 5) {
      setIsSubmitted(true);
      setFormData({
        ...formData,
        features: selectedFeatures
      });
    }
  };

  const handleAddFeature = (e) => {
    if (e.key === 'Enter' && newFeature.trim() !== "") {
      setFeatures([...features, newFeature]);
      setNewFeature("");
      setIsAddingFeature(false);
    }
  };

  const handleInputBlur = () => {
    setIsAddingFeature(false);
  };

  useEffect(() => {
    if (isAddingFeature && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingFeature]);

  const featuresCheckboxes = features.map((feature, index) => {
    const isSelected = selectedFeatures.some(item => item.feature === feature);

    return (
      <li key={index}>
        <input
          type="checkbox"
          id={`feature-${index}`}
          onChange={() => handleCheckboxChange(feature)}
          checked={isSelected}
        />
        <label htmlFor={`feature-${index}`}>{feature}</label>
        {isSelected && (
          <div className={styles.priorityOptions}>
            <label>
              <input
                type="radio"
                name={`priority-${index}`}
                value="Must have"
                checked={selectedFeatures.find(item => item.feature === feature).priority === 'Must have'}
                onChange={() => handlePriorityChange(feature, 'Must have')}
              />
              Must have
            </label>
            <label>
              <input
                type="radio"
                name={`priority-${index}`}
                value="Nice to have"
                checked={selectedFeatures.find(item => item.feature === feature).priority === 'Nice to have'}
                onChange={() => handlePriorityChange(feature, 'Nice to have')}
              />
              Nice to have
            </label>
          </div>
        )}
      </li>
    )
  });

  const renderMessage = () => {
    const selectedCount = selectedFeatures.length;
    if (selectedCount === 0) {
      return "You haven't selected any options yet";
    } else if (selectedCount < 5) {
      return `You've selected ${selectedCount} option${selectedCount > 1 ? 's' : ''}, please choose ${5 - selectedCount} more`;
    } else if (selectedCount === 5) {
      return "";
    } else {
      return "You have selected more than 5 options, please deselect some.";
    }
  };

  const handleNextStep = () => {
    setCurrentPage('Page2');
  }

  if (isSubmitted) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h3>☑ Submission successful</h3>
          <p>Please step into the black box and start the next step of your journey</p>
        </div>
        <button className={styles.button} onClick={handleNextStep}>
          I have entered the black box
        </button>
      </div>
    );
  }

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <h3>Choose the top 5 evaluation features based on the job ad</h3>
      <ul>
        {featuresCheckboxes}
      </ul>
      {isAddingFeature ? (
        <div>
          <input type="checkbox" disabled />
          <input
            type="text"
            ref={inputRef}
            value={newFeature}
            onChange={(e) => setNewFeature(e.target.value)}
            onKeyDown={handleAddFeature}
            onBlur={handleInputBlur}
            placeholder="Add a feature"
          />
        </div>
      ) : (
        <p className={styles.addFeature} onClick={() => setIsAddingFeature(true)}>🞣 Add a feature</p>
      )}
      <button
        className={styles.button}
        type="submit"
        disabled={selectedFeatures.length !== 5}
      >
        SUBMIT
      </button>
      <p className={styles.message}>{renderMessage()}</p>
    </form>
  );
};

export default Page1;
