import React, { useState } from 'react';
import axios from 'axios';
import styles from './Page4.module.css';

const Page4 = ({setCurrentPage, formData, setFormData}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionnaire, setQuestionnaire] = useState({
    humanVsAiRanking: '',
    rankingDifferences: '',
    additionalComments: '',
    ageGroup: '',
    nationality: '',
    countryOfWork: '',
    gender: '',
    stereotypes: '',
    regionalStereotypes: '',
    susceptibleGroup: '',
    altaiSuccess: '',
    altaiShortcomings: '',
    altaiSuggestions: '',
    aiHiringFeelings: '',
    additionalFeedback: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestionnaire({ ...questionnaire, [name]: value });
  };

  const handleSubmit = async (e) => {
	  e.preventDefault();
	  const updatedFormData = {
		  ...formData,
		  questionnaire: questionnaire
	  };

	  setFormData(updatedFormData);

	  try {
		  await axios.post('/api/questionnaire', updatedFormData);
		  setIsSubmitted(true);
		  setFormData({});
	  } catch (error) {
		  console.error('There was an error submitting the data:', error);
		  alert('There was an error submitting the data. Please try again.');
	  }
  };

  const handleNextStep = () => {
    setCurrentPage('Page0');
  };

  if (isSubmitted) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h3>☑ Submission successful</h3>
          <p>Thank you!</p>
        </div>
        <button className={styles.button} onClick={handleNextStep}>
          Start over
        </button>
      </div>
    );
  }

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <h3>Questionnaire</h3>
      
      <p>This questionnaire aims to gather detailed feedback on various aspects of the AI
tool's alignment with ethical guidelines, user perceptions of fairness, and potential
improvements.</p>

      <h4>Part 1: Human ranking vs. AI ranking</h4>
      <label>
        1. How is your ranking compared with the AI’s ranking?
        <select name="humanVsAiRanking" value={questionnaire.humanVsAiRanking} onChange={handleChange}>
          <option value="">Select...</option>
          <option value="Very similar">Very similar</option>
          <option value="Somewhat similar">Somewhat similar</option>
          <option value="Different">Different</option>
          <option value="Very different">Very different</option>
        </select>
      </label>
      <label>
        2. What do you think is the reason for the differences between human’s ranking and AI’s ranking?
        <textarea name="rankingDifferences" value={questionnaire.rankingDifferences} onChange={handleChange} />
      </label>
      <label>
        3. Do you have any additional comments or feedback on the ranking results?
        <textarea name="additionalComments" value={questionnaire.additionalComments} onChange={handleChange} />
      </label>

      <h4>Part 2: Demographic Information</h4>
      <label>
        4. Age group:
        <select name="ageGroup" value={questionnaire.ageGroup} onChange={handleChange}>
          <option value="">Select...</option>
          <option value="18-24">18-24</option>
          <option value="25-34">25-34</option>
          <option value="35-44">35-44</option>
          <option value="45-54">45-54</option>
          <option value="55-64">55-64</option>
          <option value="65+">65+</option>
        </select>
      </label>
      <label>
        5. Nationality:
        <input type="text" name="nationality" value={questionnaire.nationality} onChange={handleChange} />
      </label>
      <label>
        6. Country you work in:
        <input type="text" name="countryOfWork" value={questionnaire.countryOfWork} onChange={handleChange} />
      </label>
      <label>
        7. Gender:
        <select name="gender" value={questionnaire.gender} onChange={handleChange}>
          <option value="">Select...</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
          <option value="Non-binary">Non-binary</option>
          <option value="Prefer not to say">Prefer not to say</option>
          <option value="Other">Other</option>
        </select>
      </label>

      <h4>Part 3: Perceptions of Fairness and Stereotypes</h4>
      <label>
        8. What stereotypes do you think might arise with regard to the job candidates provided?
        <textarea name="stereotypes" value={questionnaire.stereotypes} onChange={handleChange} />
      </label>
      <label>
        9. Do you think these stereotypes are specific to your local region?
        <textarea name="regionalStereotypes" value={questionnaire.regionalStereotypes} onChange={handleChange} />
      </label>
      <label>
        10. Which group do you think is most susceptible to being unfairly discriminated (positively or negatively) by such a tool?
        <textarea name="susceptibleGroup" value={questionnaire.susceptibleGroup} onChange={handleChange} />
      </label>

      <h4>Part 4: the ALTAI principles on this AI ranking tool</h4>
      <p className="altai-description">
        ALTAI is the Assessment List for Trustworthy Artificial Intelligence. This tool focuses
        mainly on the highlighted requirements:
        <ul>
          <li>a. Human agency and oversight</li>
          <li>b. Technical robustness and safety</li>
          <li>c. Privacy and data governance</li>
          <li>d. Transparency</li>
          <li>e. Diversity, non-discrimination and fairness</li>
          <li>f. Environmental and societal well-being</li>
          <li>g. Accountability</li>
        </ul>
      </p>
      <label>
        11. What do you think the tool does successfully with respect to the above-mentioned ethical requirements from the Assessment List for Trustworthy AI (ALTAI)?
        <textarea name="altaiSuccess" value={questionnaire.altaiSuccess} onChange={handleChange} />
      </label>
      <label>
        12. What do you think are the AI tool’s shortcomings with respect to the ALTAI requirements?
        <textarea name="altaiShortcomings" value={questionnaire.altaiShortcomings} onChange={handleChange} />
      </label>
      <label>
        13. Do you have any suggestions to make the tool better align with the ALTAI?
        <textarea name="altaiSuggestions" value={questionnaire.altaiSuggestions} onChange={handleChange} />
      </label>
      <label>
        14. How would you feel knowing that your employer is making hiring decisions with the support of an AI ranking tool?
        <textarea name="aiHiringFeelings" value={questionnaire.aiHiringFeelings} onChange={handleChange} />
      </label>

      <label>
        15. Do you have any additional comments or feedback?
        <textarea name="additionalFeedback" value={questionnaire.additionalFeedback} onChange={handleChange} />
      </label>

      <button className={styles.button} type="submit">
        SUBMIT
      </button>
    </form>
  );
};

export default Page4;
