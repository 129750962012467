import React, { useState } from 'react';
import styles from './Page2.module.css';

const Page2 = ({setCurrentPage, formData, setFormData}) => {
  const [selectedCandidates, setSelectedCandidates] = useState(["", "", "", ""]);
  const [explanation, setExplanation] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleCandidateChange = (index, value) => {
    const newSelectedCandidates = [...selectedCandidates];
    newSelectedCandidates[index] = value;
    setSelectedCandidates(newSelectedCandidates);
  };

  const isFormValid = () => {
    const uniqueCandidates = new Set(selectedCandidates);
    return (
      selectedCandidates.every(candidate => candidate !== "") &&
      uniqueCandidates.size === selectedCandidates.length
    );
  };

  const allCandidates = ["Felix", "Isabela", "Mohammed", "Priya"];

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setFormData({
      ...formData,
      ranking: { candidates: selectedCandidates, explanation: explanation }
    });
  };

  const handleNextStep = () => {
    setCurrentPage('Page3');
  };

  if (isSubmitted) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h3>☑ Submission successful</h3>
          <p>Please step out of the black box and check out the AI ranking</p>
        </div>
        <button className={styles.button} onClick={handleNextStep}>
          I am out of the black box now
        </button>
      </div>
    );
  }

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <div className={styles.content}>
        <h3>Based on your selected features, how would you rank 4 candidates?</h3>
        {selectedCandidates.map((candidate, index) => (
          <div key={index} className={styles.candidateSelect}>
            <label htmlFor={`candidate-${index}`}>No. <span className={styles.number}>{index + 1}</span></label>
            <select
              id={`candidate-${index}`}
              value={candidate}
              onChange={(e) => handleCandidateChange(index, e.target.value)}
              placeholder="Select one"
            >
              <option value="" disabled>Select one</option>
              {allCandidates.map(availableCandidate => (
                <option key={availableCandidate} value={availableCandidate}>
                  {availableCandidate}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
      <div className={styles.content}>
        <h3>Could you please provide some explanations on the ranking?</h3>
        <textarea
          placeholder="Enter your explanation here"
          value={explanation}
          onChange={(e) => setExplanation(e.target.value)}
        />
      </div>
      <button className={styles.button} type="submit" disabled={!isFormValid()}>SUBMIT</button>
    </form>
  );
};

export default Page2;
