// src/components/Page0.js

import React from 'react';
import styles from './Page0.module.css';

const Page0 = ({setCurrentPage}) => {
  const handleStart = () => {
    setCurrentPage('Page1');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h2>Hiring black box</h2>
        <p>Are you better at job recruitment than an Artificial Intelligence?</p>
      </div>
      <button className={styles.button} onClick={handleStart}>Start Now</button>
    </div>
  );
};

export default Page0;
